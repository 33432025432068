<template>
	<div class="page" :class="{ 'immerse-wrap': !isKiosk }">
		<div
			:class="{ 'immerse-content': !isKiosk }"
			:style="{ marginBottom: !canBuy ? '0' : null }"
		>
			<!--商品詳情 手機點餐開始-->
			<div class="product-img" v-if="product.itemLogoUrl && !isKiosk">
				<img
					:src="`${httpUrl}${product.itemLogoUrl}`"
					@error="handleItemPicLoadError"
					alt
				/>
			</div>
			<div class="info" v-if="!isKiosk">
				<div class="title">{{ product.itemName }}</div>
				<div class="price">$ {{ $stepPrice(product.price) }}</div>
				<div class="content">{{ product.description }}</div>

				<!--商品自動折扣樣式 手機點餐-->
				<div class="automatic-discount" v-if="product.automaticDiscountId">
					<p>折扣：</p>
					<div class="automatic-discount-content">
						<span> 「{{ product.automaticDiscountName }}」 </span>
						<span>-${{ Math.floor(product.discountPrice) }}</span>
					</div>
				</div>
			</div>
			<!--商品詳情 手機點餐結束-->

			<!--商品詳情 前台點餐開始-->
			<div class="kiosk-info" v-else>
				<div class="picture" v-if="product.itemLogoUrl">
					<img
						:src="`${httpUrl}${product.itemLogoUrl}`"
						@error="handleItemPicLoadError"
						alt
					/>
				</div>
				<div class="info-block">
					<div class="title">
						<div class="type">
							<ByIcon icon="king" size="18" v-if="product.itemType === 2" />
							<ByIcon icon="new" size="18" v-if="product.itemType === 3" />
						</div>
						<div class="text">
							{{ product.itemName }}
						</div>
					</div>
					<div class="content">{{ product.description }}</div>
					<div class="panel">
						<ByAmountPicker
							class="amount"
							v-model="product.count"
							:disable="isFreeItem"
						></ByAmountPicker>
						<div class="price">$ {{ $stepPrice(product.price) }}</div>
					</div>
				</div>
			</div>
			<!--商品自動折扣樣式 前台點餐-->
			<div
				class="kiosk-info-automatic-discount"
				v-if="isKiosk && product.automaticDiscountId"
			>
				<p>折扣：</p>
				<div class="kiosk-info-automatic-discount-content">
					<span> 「{{ product.automaticDiscountName }}」 </span>
					<span>-${{ Math.floor(product.discountPrice) }}</span>
				</div>
			</div>
			<!--商品詳情 前台點餐結束-->
			<div
				class="option__name__expend"
				v-if="product.optionList && product.optionList.length"
				@click="$set(product, 'isShowFlavor', !product.isShowFlavor)"
			>
				<span>選擇口味</span>
				<img
					src="../../../assets/back.svg"
					class="arrow"
					:class="{ active: product.isShowFlavor }"
				/>
			</div>
			<BySlider
				v-if="product.optionList && product.optionList.length"
				v-show="product.isShowFlavor"
			>
				<Flavor :data="product.optionList || []" />
			</BySlider>

			<div
				class="combo"
				v-if="product.upgradeList && product.upgradeList.length > 1"
			>
				<div class="combo__item__name">升級</div>
				<div
					v-for="(upgrade, index) in product.upgradeList"
					:key="'combo-' + index"
					class="combo__item"
				>
					<ByRadio
						class="name__text"
						:name="String(upgrade.upgradeId)"
						:title="`${upgrade.name} ${
							upgrade.price ? ` +$ ${$stepPrice(upgrade.price)}` : ''
						}`"
						:required="true"
						v-model="product.upgradeListId"
						v-if="canBuy"
					>
						<div v-if="upgrade.remark" class="content">
							{{ upgrade.remark }}
						</div>
					</ByRadio>
					<div v-if="String(upgrade.upgradeId) === product.upgradeListId">
						<UpgradeCard :_upgrade.sync="upgrade" />
					</div>
				</div>
			</div>

			<div
				class="combo"
				v-if="product.comboList && product.comboList.length > 0"
			>
				<div
					v-for="(combo, index) in product.comboList"
					:key="'combo-' + index"
					class="combo__item"
				>
					<div style="display: flex; align-items: center">
						<div class="combo__item__name new-combo">{{ combo.name }}</div>
						<div
							class="combo__name__expend"
							@click="$set(combo, 'isShowFlavor', !combo.isShowFlavor)"
						>
							<span>
								<span class="tag" v-if="combo.leastQuantity > 0">*</span>
								<span
									class="quantityText"
									:class="{ active: combo.leastQuantity > 0 }"
								>
									{{ getQuantityText(combo) }}
								</span>
							</span>
							<img
								src="../../../assets/back.svg"
								class="arrow"
								:class="{ active: combo.isShowFlavor }"
							/>
						</div>
					</div>
					<div
						v-if="combo.errorValidate"
						class="errorValidate errorValidate__combo"
					>
						- 請選擇餐點
					</div>
					<ComboCard :_combo.sync="combo" />
				</div>
			</div>

			<div class="remark left" v-if="canBuy">
				<div class="remark-title">備註(非必填)</div>
				<ByInput
					type="textarea"
					variant="textareaBasic"
					:value="product.remark"
					@input="$set(product, 'remark', $event)"
				/>
			</div>

			<ByFooter v-if="!isKiosk" />
		</div>
		<div class="control-block" v-if="!isKiosk && canBuy" />

		<div class="order-control immerse-control-panel" v-if="!isKiosk && canBuy">
			<div class="order-control-bottom">
				<div class="title">{{ product.itemName }}</div>
				<ByAmountPicker
					class="product-amount"
					v-model="product.count"
					v-if="!isKiosk && canBuy"
					:disable="isFreeItem"
				></ByAmountPicker>
			</div>

			<!--自動折扣-->
			<div class="order-control-bottom" v-if="product.automaticDiscountId">
				<div class="automatic-discount">
					<div>{{ product.automaticDiscountName }}</div>
					<div>-${{ Math.floor(product.discountPrice * product.count) }}</div>
				</div>
			</div>
			<div class="order-control-bottom">
				<div class="order-control-bottom-text">共 {{ totalMoney }} 元</div>
				<div class="order-control-bottom-btn">
					<ByButton stl="main2" @click="goBackClick" :loading="false">
						{{ !isEdit ? '返回' : '取消' }}
					</ByButton>
					<ByButton stl="main1" @click="cartClick" :loading="false">
						{{ !isEdit ? '加入購物車' : '更新購物車' }}
					</ByButton>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Flavor from '@/views/buy/Detail/Flavor'
import UpgradeCard from './UpgradeCard.vue'
import ComboCard from '@/views/buy/Detail/ComboCard.vue'
import ByRadio from '@/components/Radio.vue'
import {
	isComboContentAllNotSale,
	isUpgradeContentAllNotSale,
} from '@/utils/order'
import ByAmountPicker from '@/components/AmountPicker.vue'
import ByIcon from '@/components/Icon.vue'
import BySlider from '@/components/BySlider.vue'
import ByInput from '@/components/Input.vue'
import ByFooter from '@/components/Footer.vue'
import ByButton from '@/components/Button.vue'

export default {
	components: {
		ByButton,
		ByFooter,
		ByInput,
		BySlider,
		ByIcon,
		ByAmountPicker,
		ByRadio,
		Flavor,
		UpgradeCard,
		ComboCard,
	},
	props: {
		detailId: {
			type: Number | String,
			required: false,
		},
		isPropEdit: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data() {
		return {
			product: {},
		}
	},
	created() {
		setTimeout(function () {
			window.scrollTo(0, 0)
		}, 0)
		this.product = this.getDetail()
	},
	computed: {
		httpUrl() {
			return window.location.origin
		},
		isEdit() {
			return this.isPropEdit || this.$route.query?.edit
		},
		isKiosk() {
			return this.$store.getters.isKiosk
		},
		canBuy() {
			return this.$store.getters.canBuy
		},
		totalMoney() {
			if (!this.product) {
				return 0
			}

			let money = 0
			const item = this.product

			money += item.price

			function calculateOptionPrice(optionList) {
				let optionPrice = 0
				optionList.forEach(option => {
					if (option.type) {
						const sameOptionDetail = option.optionDetailList?.filter(
							optionDetail =>
								option.count?.find(v => v === optionDetail.id.toString()),
						)
						if (sameOptionDetail) {
							sameOptionDetail?.forEach(
								optionDetail =>
									(optionPrice += optionDetail.price * optionDetail.count),
							)
						}
					} else {
						const sameOptionDetail = option.optionDetailList?.find(
							optionDetail => optionDetail.id.toString() === option.count,
						)
						if (sameOptionDetail) {
							optionPrice += sameOptionDetail.price * sameOptionDetail.count
						}
					}
				})
				return optionPrice
			}

			//判斷口味的$$
			if (item?.optionList && item?.optionList?.length > 0) {
				money += calculateOptionPrice(item.optionList)
			}

			function calculateUpgradePrice(upgradeList) {
				let upgradePrice = 0
				const sameUpgrade = upgradeList.find(
					upgrade =>
						(upgrade?.upgradeId && (upgrade?.upgradeId).toString()) ===
						item?.upgradeListId,
				)
				if (sameUpgrade) {
					upgradePrice += sameUpgrade?.price
					sameUpgrade?.upgradeContentList?.forEach(upgradeContent => {
						if (upgradeContent?.select) {
							const sameUpgradeContentDetail =
								upgradeContent?.upgradeContentDetailList?.filter(
									upgradeContentDetail =>
										upgradeContent?.select.find(
											v =>
												v ===
												(upgradeContentDetail?.upgradeContentDetailId).toString(),
										),
								)
							if (sameUpgradeContentDetail.length > 0) {
								sameUpgradeContentDetail.forEach(upgradeContentDetail => {
									let price = upgradeContentDetail?.price
									let optionPrice = 0
									if (
										upgradeContentDetail?.optionList &&
										upgradeContentDetail?.optionList?.length > 0
									) {
										upgradeContentDetail?.optionSaveList.forEach(optionList => {
											optionList.forEach(option => {
												if (option.type) {
													const same = option.optionDetailList?.filter(
														optionDetail =>
															option.count?.find(
																v => v === optionDetail.id.toString(),
															),
													)
													if (same) {
														same?.forEach(
															i => (optionPrice += i.price * i.count),
														)
													}
												} else {
													const same = option.optionDetailList?.find(
														optionDetail =>
															optionDetail.id.toString() === option.count,
													)
													if (same) {
														optionPrice += same.price * same.count
													}
												}
											})
										})
									}
									upgradePrice +=
										price * upgradeContentDetail.count + optionPrice
								})
							}
						}
					})
				}
				return upgradePrice
			}

			//判斷升級的$$
			if (item?.upgradeListId && item?.upgradeList?.length > 0) {
				money += calculateUpgradePrice(item.upgradeList)
			}

			function calculateComboPrice(comboList) {
				let comboPrice = 0

				comboList.forEach(combo => {
					const sameComboDetail = combo?.detailList?.filter(detail =>
						combo?.select.find(combo => combo === (detail?.id).toString()),
					)
					if (sameComboDetail.length > 0) {
						sameComboDetail.forEach(detail => {
							let price = detail?.price
							let optionPrice = 0
							if (detail?.optionList && detail?.optionList?.length > 0) {
								detail?.optionSaveList.forEach(optionList => {
									optionList.forEach(option => {
										if (option.type) {
											const sameOptionDetail = option.optionDetailList?.filter(
												optionDetail =>
													option.count?.find(
														v => v === optionDetail.id.toString(),
													),
											)
											if (sameOptionDetail) {
												sameOptionDetail?.forEach(
													optionDetail =>
														(optionPrice +=
															optionDetail.price * optionDetail.count),
												)
											}
										} else {
											const sameOptionDetail = option.optionDetailList?.find(
												optionDetail =>
													optionDetail.id.toString() === option.count,
											)
											if (sameOptionDetail) {
												optionPrice +=
													sameOptionDetail.price * sameOptionDetail.count
											}
										}
									})
								})
							}
							comboPrice += price * detail.count + optionPrice
						})
					}
				})
				return comboPrice
			}

			//判斷套餐的$$
			if (item?.comboList?.length > 0) {
				money += calculateComboPrice(item.comboList)
			}

			money = money * item.count

			// 扣除自動折扣金額
			if (item.discountPrice) {
				money -= item.count * item.discountPrice
			}
			return Math.ceil(money)
		},
		itemAutomaticDiscountList() {
			return this.$store.state.store.itemAutomaticDiscountList
		},
		// 訂單自動折扣
		useOrderAutomaticDiscount() {
			return this.$store.state.user.useOrderAutomaticDiscount
		},
		cart() {
			return this.$store.getters['user/currCart']
		},
		cartHasFreeItem() {
			return this.$store.getters['user/currCartHasFreeItem']
		},
		// 當前頁面符合滿額贈品
		isFreeItem() {
			if (this.isEdit && this.product.freeItem) {
				return true
			}
			return (
				this.useOrderAutomaticDiscount &&
				this.useOrderAutomaticDiscount?.discountType === 'FREE_ITEM' &&
				this.product.itemId ===
					this.useOrderAutomaticDiscount?.automaticDiscountItemList[0].itemId &&
				!this.cartHasFreeItem
			)
		},
	},
	methods: {
		getQuantityText(content) {
			if (isComboContentAllNotSale(content)) {
				return ``
			}
			const { leastQuantity, mostQuantity } = content
			if (leastQuantity === 0) {
				if (mostQuantity) {
					return `可選${mostQuantity}項`
				} else {
					return ``
				}
			} else {
				if (leastQuantity === mostQuantity) {
					return `必選${leastQuantity}項`
				} else {
					if (mostQuantity) {
						return `必選${leastQuantity}~${mostQuantity}項`
					} else {
						return `必選${leastQuantity}項以上`
					}
				}
			}
		},
		computedOptionCount(optionList) {
			optionList.forEach(item => {
				if (!item.count) {
					if (item.type) {
						item.count = []
					} else {
						item.count = ''
					}
				}

				if (item.errorValidate === undefined) {
					item.errorValidate = false
				}

				item.optionDetailList.forEach(o_item => {
					o_item.type = item.type
					if (!o_item.count) {
						o_item.count = 1
					}
				})
			})
			return optionList
		},
		radioIsShowFlavor(list, e) {
			list.comboItem.forEach(i => {
				if (i.id.toString() === e) {
					this.$set(i, 'isShowFlavor', true)
				} else {
					this.$set(i, 'isShowFlavor', false)
				}
			})
		},
		handleChange(index, itemId, item) {
			const selectComboIds = this.product.comboList[index].count
			const selectIndex = selectComboIds.findIndex(e => e === itemId)
			const hasOption = selectIndex !== -1
			this.$set(item, 'isShowFlavor', !hasOption)
			if (hasOption) {
				selectComboIds.splice(selectIndex, 1)
			} else {
				selectComboIds.push(itemId)
			}
		},
		updateOptionList(optionList) {
			if (optionList?.length) {
				this.computedOptionCount(optionList)
			} else {
				this.computedOptionCount([])
			}
		},
		updateUpgradeList(upgradeList) {
			upgradeList = upgradeList.filter(upgrade => {
				let can = true
				upgrade.upgradeContentList?.forEach(upgradeContent => {
					const us = upgradeContent.upgradeContentDetailList.find(
						upgradeContentDetail => upgradeContentDetail.itemStatus,
					)
					if (!us) {
						can = false
					}
				})
				return can
			})

			if (upgradeList.length > 0) {
				upgradeList.forEach(upgrade => {
					upgrade.upgradeContentList?.forEach(upgradeContent => {
						upgradeContent.isShowFlavor = true
						upgradeContent.errorValidate = false
						upgradeContent.select = []

						// 刪除 已下架品項 分類狀態、餐點狀態
						upgradeContent.upgradeContentDetailList =
							upgradeContent.upgradeContentDetailList.filter(
								upgradeContentDetail => {
									return upgradeContentDetail.itemStatus
								},
							)

						upgradeContent.upgradeContentDetailList.forEach(
							upgradeContentDetail => {
								upgradeContentDetail.isShowFlavor = [true]
								upgradeContentDetail.count = 1
								this.computedOptionCount(upgradeContentDetail?.optionList || [])
								upgradeContentDetail.optionSaveList = [
									JSON.parse(JSON.stringify(upgradeContentDetail?.optionList)),
								]
							},
						)
					})
				})
			}
		},
		updateComboList(comboList) {
			comboList = comboList.filter(combo => {
				let can = true
				const us = combo.detailList.find(detail => detail.itemStatus)
				if (!us) {
					can = false
				}
				return can
			})

			if (comboList.length > 0) {
				comboList.forEach(combo => {
					combo.errorValidate = false
					combo.select = []

					// 刪除 已下架品項 分類狀態、餐點狀態
					combo.detailList = combo.detailList.filter(detail => {
						return detail.itemStatus
					})

					combo.detailList.forEach(detail => {
						detail.isShowFlavor = [true]
						detail.count = 1
						this.computedOptionCount(detail?.optionList || [])
						detail.optionSaveList = [
							JSON.parse(JSON.stringify(detail?.optionList)),
						]
					})
				})
			}
		},
		updateProductOptionSaveListUsingCache(optionSaveList, cacheOptionSaveList) {
			optionSaveList.forEach((currentOptions, currentOptionsIndex) => {
				currentOptions?.forEach(currentOption => {
					const sameCacheOption = cacheOptionSaveList?.[
						currentOptionsIndex
					]?.find(
						cacheSelectedOption => cacheSelectedOption.id === currentOption.id,
					)

					if (sameCacheOption && sameCacheOption.type === currentOption.type) {
						currentOption.count = sameCacheOption.count

						currentOption.optionDetailList.forEach(currentOptionDetail => {
							const sameCacheOptionDetail =
								sameCacheOption.optionDetailList?.find(
									cacheOptionDetail =>
										cacheOptionDetail.id === currentOptionDetail.id,
								)

							if (sameCacheOptionDetail) {
								currentOptionDetail.count = sameCacheOptionDetail.count
							}
						})
					}
				})
			})
		},
		updateProductUsingCacheCart(product) {
			const cacheCart = this.$store.state.detail.cacheCart

			product.remark = cacheCart.itemRemark
			product.count = cacheCart.count
			product.freeItem = cacheCart.freeItem
			product.automaticDiscountId = cacheCart.automaticDiscountId
			product.automaticDiscountName = cacheCart.automaticDiscountName
			product.automaticDiscountItemId = cacheCart.automaticDiscountItemId
			product.discountPrice = cacheCart.discountPrice

			product.optionList?.forEach(currentOption => {
				const sameCacheOption = cacheCart.optionList.find(
					cacheOption => cacheOption.id === currentOption.id,
				)
				if (sameCacheOption && sameCacheOption.type === currentOption.type) {
					currentOption.count = sameCacheOption.count
					currentOption.optionDetailList.forEach(currentOptionDetail => {
						const sameCacheOptionDetail = sameCacheOption.optionDetailList.find(
							cacheOptionDetail =>
								cacheOptionDetail.id === currentOptionDetail.id,
						)
						if (sameCacheOptionDetail) {
							currentOptionDetail.count = sameCacheOptionDetail.count
						}
					})
				}
			})

			if (cacheCart.upgradeListId) {
				product.upgradeListId = cacheCart.upgradeListId

				product.upgradeList?.forEach(currentUpgrade => {
					if (String(currentUpgrade.upgradeId) === cacheCart.upgradeListId) {
						currentUpgrade?.upgradeContentList?.forEach(
							currentUpgradeContent => {
								const sameCacheUpgradeContent = cacheCart.upgradeSelect.find(
									cacheUpgrade =>
										cacheUpgrade.upgradeContentId ===
										currentUpgradeContent.upgradeContentId,
								)

								if (sameCacheUpgradeContent) {
									currentUpgradeContent.select = sameCacheUpgradeContent.select

									currentUpgradeContent.upgradeContentDetailList?.forEach(
										currentUpgradeContentDetail => {
											const sameCacheContentDetail =
												sameCacheUpgradeContent.upgradeContentDetails.find(
													cacheContentDetail =>
														cacheContentDetail.upgradeContentDetail
															.upgradeContentDetailId ===
														currentUpgradeContentDetail.upgradeContentDetailId,
												)

											if (sameCacheContentDetail) {
												currentUpgradeContentDetail.count =
													sameCacheContentDetail.upgradeContentDetail.count
												currentUpgradeContentDetail.isShowFlavor =
													sameCacheContentDetail.upgradeContentDetail.isShowFlavor
												currentUpgradeContentDetail.optionSaveList =
													sameCacheContentDetail.upgradeContentDetail.optionSaveList
												this.updateProductOptionSaveListUsingCache(
													currentUpgradeContentDetail.optionSaveList,
													sameCacheContentDetail.upgradeContentDetail
														.optionSaveList,
												)
											}
										},
									)
								}
							},
						)
					}
				})
			}

			if (cacheCart.comboList?.length > 0) {
				product.comboList.forEach(currentCombo => {
					const sameCacheCombo = cacheCart.comboSelect.find(
						cacheCombo => cacheCombo.id === currentCombo.id,
					)
					if (sameCacheCombo) {
						currentCombo.select = sameCacheCombo.select

						currentCombo.detailList?.forEach(currentDetail => {
							const sameCacheComboDetail = sameCacheCombo.details.find(
								cacheComboDetail =>
									cacheComboDetail.detail.id === currentDetail.id,
							)

							if (sameCacheComboDetail) {
								currentDetail.count = sameCacheComboDetail.detail.count
								currentDetail.isShowFlavor =
									sameCacheComboDetail.detail.isShowFlavor
								currentDetail.optionSaveList =
									sameCacheComboDetail.detail.optionSaveList
								this.updateProductOptionSaveListUsingCache(
									currentDetail.optionSaveList,
									sameCacheComboDetail.optionSaveList,
								)
							}
						})
					}
				})
			}
		},
		initMenuItemUpgradeList(upgradeList) {
			function handleOptionDetails(optionDetailList = []) {
				return optionDetailList.map(optionDetail => ({
					...optionDetail,
					count: '',
					optionDetailSelect: '',
				}))
			}

			function handleOptions(optionList = []) {
				return optionList.map(option => ({
					...option,
					count: '',
					errorValidate: false,
					optionDetailList: handleOptionDetails(option.optionDetailList),
				}))
			}

			function handleContentDetails(upgradeContentDetailList = []) {
				return upgradeContentDetailList.map(contentDetail => ({
					...contentDetail,
					count: '',
					isShowFlavor: [],
					optionList: handleOptions(contentDetail.optionList),
					optionSaveList: handleOptions(contentDetail.optionList),
				}))
			}

			function handleContents(upgradeContentList = []) {
				return upgradeContentList.map(content => ({
					...content,
					select: '',
					isShowFlavor: true,
					errorValidate: false,
					upgradeContentDetailList: handleContentDetails(
						content.upgradeContentDetailList,
					),
				}))
			}

			let newUpgradeList = (upgradeList || []).map(upgrade => ({
				...upgrade,
				select: '',
				upgradeContentList: handleContents(upgrade.upgradeContentList),
			}))

			newUpgradeList.unshift({
				upgradeId: null,
				name: '無',
				select: false,
			})

			return newUpgradeList
		},
		initMenuItemComboList(comboList) {
			function handleOptionDetails(optionDetailList = []) {
				return optionDetailList.map(optionDetail => ({
					...optionDetail,
					count: '',
					optionDetailSelect: '',
				}))
			}

			function handleOptions(optionList = []) {
				return optionList.map(option => ({
					...option,
					count: '',
					errorValidate: false,
					optionDetailList: handleOptionDetails(option.optionDetailList),
				}))
			}

			function handleDetails(detailList = []) {
				return detailList.map(detail => ({
					...detail,
					count: '',
					select: '',
					isShowFlavor: [],
					optionList: handleOptions(detail.optionList),
					optionSaveList: handleOptions(detail.optionList),
				}))
			}

			return (comboList || []).map(combo => ({
				...combo,
				select: '',
				isShowFlavor: true,
				errorValidate: false,
				detailList: handleDetails(combo.detailList),
			}))
		},
		initMenuDetail() {
			const menus = this.$store.state.store.menus
			if (!menus) {
				return
			}

			let item = null
			const detailId = this.detailId || this.$route.params.id
			for (const menu of menus) {
				item = menu.itemList?.find(item => item.itemId === detailId)
				if (item) {
					break
				}
			}

			const newOptionList = (item.optionList || []).map(option => ({
				...option,
				count: '',
			}))
			const newUpgradeList = this.initMenuItemUpgradeList(item.upgradeList)
			const newComboList = this.initMenuItemComboList(item.comboList)

			// 計算目前購物車是否符合可用滿額贈品
			if (this.isFreeItem) {
				item.freeItem = true
				item.automaticDiscountId = this.useOrderAutomaticDiscount.id
				item.automaticDiscountItemId = this.automaticDiscountItemList[0].id
				item.automaticDiscountName = this.useOrderAutomaticDiscount.name
				item.discountPrice = item.itemPrice
			}

			return {
				...item,
				count: 1,
				isShowFlavor: true,
				optionList: newOptionList,
				upgradeListId: null,
				upgradeList: newUpgradeList,
				comboList: newComboList,
			}
		},
		getDetail() {
			if (this.isEdit && this.$store.state.detail.cacheCart == null) {
				this.resetCacheCart()
				this.$router.replace('/Detail/' + this.$route.params.id)
			}

			const product = this.initMenuDetail()

			this.updateOptionList(product?.optionList)

			if (product?.upgradeList?.length) {
				this.updateUpgradeList(product.upgradeList)
			}

			if (product?.comboList?.length) {
				this.updateComboList(product.comboList)
			}

			if (this.isEdit && this.$store.state.detail.cacheCart != null) {
				this.updateProductUsingCacheCart(product)
			}

			return product
		},
		checkStoreOptionList(store) {
			let isValidate = true
			store.optionList.forEach(f => {
				// 複選
				if (f.type && f.count.length > 0) {
					f.errorValidate = false
					f.count.forEach(a => {
						const same = f.optionDetailList.find(v => String(v.id) === a)
						if (same) {
							store.optionSelect.push({
								...f,
								optionDetailSelect: same,
							})
						}
					})

					// 單選
				} else if (!f.type && f.count !== '') {
					f.errorValidate = false
					store.optionSelect.push({
						...f,
						optionDetailSelect: f.optionDetailList.find(
							v => String(v.id) === f.count,
						),
					})
				} else if (!f.type && f.count === '' && f.required) {
					isValidate = false
					f.errorValidate = true
				} else if (f.type && !f.count.length > 0 && f.required) {
					isValidate = false
					f.errorValidate = true
				} else {
					f.errorValidate = false
				}
			})
			return isValidate
		},
		checkStoreUpgradeListAndPushToUpgradeSelect(store) {
			const upgrade = store.upgradeList.find(
				v => String(v.upgradeId) === store.upgradeListId,
			)

			if (upgrade) {
				let isValidate = true
				store.upgradeName = upgrade.name
				store.upgradePrice = upgrade.price

				upgrade.upgradeContentList?.forEach(upgradeContent => {
					const upgradeContentDetail =
						upgradeContent.upgradeContentDetailList.filter(
							upgradeContentDetail =>
								upgradeContent.select.includes(
									String(upgradeContentDetail.upgradeContentDetailId),
								),
						)
					const totalUpgradeCount = upgradeContentDetail.reduce(
						(a, b) => a + b.count,
						0,
					)

					if (isUpgradeContentAllNotSale(upgradeContent)) {
						isValidate = true
						upgradeContent.errorValidate = false
						upgradeContent.isShowFlavor = false
					} else if (
						totalUpgradeCount < upgradeContent.leastQuantity ||
						(upgradeContent.mostQuantity !== null &&
							totalUpgradeCount > upgradeContent.mostQuantity)
					) {
						isValidate = false
						upgradeContent.errorValidate = true
						upgradeContent.isShowFlavor = true
					} else {
						upgradeContent.errorValidate = false
						let optionErrorValidate = false
						let optionsResponse = {}
						upgradeContentDetail.forEach(detail => {
							let optionResponse = []
							detail.optionSaveList.forEach((optionList, optionListIndex) => {
								optionResponse[optionListIndex] = []
								optionList.forEach(option => {
									// 複選
									if (option.type && option.count.length > 0) {
										option.errorValidate = false
										option.count.forEach(a => {
											const same = option.optionDetailList.find(
												optionDetail => String(optionDetail.id) === a,
											)
											if (same) {
												optionResponse[optionListIndex].push({
													...option,
													optionDetailSelect: same,
												})
											}
										})
										// 單選
									} else if (!option.type && option.count !== '') {
										option.errorValidate = false
										optionResponse[optionListIndex].push({
											...option,
											optionDetailSelect: option.optionDetailList.find(
												v => String(v.id) === option.count,
											),
										})
									} else if (
										!option.type &&
										option.count === '' &&
										option.required
									) {
										isValidate = false
										optionErrorValidate = true
										option.errorValidate = true
										this.$set(detail.isShowFlavor, optionListIndex, true)
										upgradeContent.isShowFlavor = true
									} else if (
										option.type &&
										!option.count.length &&
										option.required
									) {
										isValidate = false
										optionErrorValidate = true
										option.errorValidate = true
										this.$set(detail.isShowFlavor, optionListIndex, true)
										upgradeContent.isShowFlavor = true
									} else {
										option.errorValidate = false
									}
								})
							})

							optionsResponse[detail.upgradeContentDetailId] = optionResponse
						})

						store.upgradeSelect.push({
							...upgradeContent,

							upgradeContentDetails: [
								...upgradeContent.upgradeContentDetailList?.reduce((a, b) => {
									if (
										upgradeContent.select.includes(
											String(b.upgradeContentDetailId),
										)
									) {
										return [
											...a,
											{
												upgradeContentDetail: b,
												optionSelect: optionsResponse[b.upgradeContentDetailId],
											},
										]
									} else {
										return a
									}
								}, []),
							],
						})
					}
				})

				return isValidate
			}
		},
		checkStoreComboListAndPushToComboSelect(store) {
			let isValidate = true

			store.comboList.forEach(currentCombo => {
				const comboDetail = currentCombo.detailList.filter(currentDetail =>
					currentCombo.select.includes(String(currentDetail.id)),
				)
				const totalComboCount = comboDetail.reduce(
					(totalCount, currentDetail) => totalCount + currentDetail.count,
					0,
				)

				if (isComboContentAllNotSale(currentCombo)) {
					isValidate = true
					currentCombo.errorValidate = false
					currentCombo.isShowFlavor = false
				} else if (
					totalComboCount < currentCombo.leastQuantity ||
					(currentCombo.mostQuantity !== null &&
						totalComboCount > currentCombo.mostQuantity)
				) {
					isValidate = false
					currentCombo.errorValidate = true
					currentCombo.isShowFlavor = true
				} else {
					currentCombo.errorValidate = false
					let optionErrorValidate = false
					let optionsResponse = {}

					comboDetail.forEach(currentDetail => {
						let optionResponse = []
						currentDetail.optionSaveList.forEach(
							(optionList, optionListIndex) => {
								optionResponse[optionListIndex] = []
								optionList.forEach(currentOption => {
									if (currentOption.type && currentOption.count.length > 0) {
										// 複選
										currentOption.errorValidate = false

										currentOption.count.forEach(currentOptionDetailId => {
											const same = currentOption.optionDetailList.find(
												currentOptionDetail =>
													String(currentOptionDetail.id) ===
													currentOptionDetailId,
											)
											if (same) {
												optionResponse[optionListIndex].push({
													...currentOption,
													optionDetailSelect: same,
												})
											}
										})
									} else if (
										!currentOption.type &&
										currentOption.count !== ''
									) {
										// 單選
										currentOption.errorValidate = false

										optionResponse[optionListIndex].push({
											...currentOption,
											optionDetailSelect: currentOption.optionDetailList.find(
												currentOptionDetail =>
													String(currentOptionDetail.id) ===
													currentOption.count,
											),
										})
									} else if (
										!currentOption.type &&
										currentOption.count === '' &&
										currentOption.required
									) {
										isValidate = false
										optionErrorValidate = true
										currentOption.errorValidate = true
										this.$set(currentDetail.isShowFlavor, optionListIndex, true)
										currentCombo.isShowFlavor = true
									} else if (
										currentOption.type &&
										!currentOption.count.length &&
										currentOption.required
									) {
										isValidate = false
										optionErrorValidate = true
										currentOption.errorValidate = true
										this.$set(currentDetail.isShowFlavor, optionListIndex, true)
										currentCombo.isShowFlavor = true
									} else {
										currentOption.errorValidate = false
									}
								})
							},
						)

						optionsResponse[currentDetail.id] = optionResponse
					})

					store.comboSelect.push({
						...currentCombo,
						details: [
							...currentCombo.detailList?.reduce(
								(accumulatedDetails, currentDetail) => {
									if (currentCombo.select.includes(String(currentDetail.id))) {
										return [
											...accumulatedDetails,
											{
												detail: currentDetail,
												optionSelect: optionsResponse[currentDetail.id],
											},
										]
									} else {
										return accumulatedDetails
									}
								},
								[],
							),
						],
					})
				}
			})

			return isValidate
		},
		transformProduct() {
			const e = this.product

			const store = {
				itemId: e.itemId,
				itemName: e.itemName,
				count: e.count,
				itemPrice: e.price,
				automaticDiscountId: e.automaticDiscountId || null,
				automaticDiscountName: e.automaticDiscountName || null,
				automaticDiscountItemId: e.automaticDiscountItemId || null,
				discountPrice: e.discountPrice || null, // 注意價格是單價 所以折扣金額也是單個折扣金額
				freeItem: e.freeItem || false, // 可以改成從組件狀態拿
				itemRemark: e.remark || '',
				optionList: e.optionList || [],
				optionSelect: [],
				upgradeList: e.upgradeList || [],
				upgradeSelect: [],
				upgradeListId: e.upgradeListId === 'null' ? null : e.upgradeListId,
				upgradeName: '',
				upgradePrice: 0,
				comboList: e.comboList || [],
				comboSelect: [],
			}

			// 口味選擇校驗
			let isValidate = this.checkStoreOptionList(store)
			let isOrderUpgradeValidate = null
			let isOrderComboValidate = null

			// 升級選擇校驗
			if (store.upgradeListId) {
				const isUpgradeValidate =
					this.checkStoreUpgradeListAndPushToUpgradeSelect(store)
				if (isUpgradeValidate !== null && isUpgradeValidate !== undefined) {
					isOrderUpgradeValidate = isUpgradeValidate
				}
			}

			// 套餐選擇校驗
			if (store.comboList.length > 0) {
				const isComboValidate =
					this.checkStoreComboListAndPushToComboSelect(store)
				if (isComboValidate !== null && isComboValidate !== undefined) {
					isOrderComboValidate = isComboValidate
				}
			}

			if (
				isValidate === false ||
				isOrderUpgradeValidate === false ||
				isOrderComboValidate === false
			) {
				this.$nextTick(() => {
					const dom = document.getElementsByClassName('errorValidate')

					if (dom && dom.length > 0) {
						if (this.isKiosk) {
							const domMom = document.getElementsByClassName(
								'by-dialog__content-wrap',
							)

							if (domMom[0]) {
								domMom[0].scrollTop = dom[0].offsetTop - 65
							}
						} else {
							document.documentElement.scrollTop = dom[0].offsetTop - 65
						}
					}
				})
			}

			isValidate = !(
				isValidate === false ||
				isOrderUpgradeValidate === false ||
				isOrderComboValidate === false
			)

			return { store, isValidate }
		},
		goBack() {
			this.$router.go(-1)
		},
		goBackClick() {
			!this.isEdit ? this.goBack() : this.handleCancelEdit()
		},
		cartClick() {
			!this.isEdit ? this.onAddCart() : this.handleEdit()
		},
		onAddCart() {
			const { store, isValidate } = this.transformProduct()
			if (!isValidate) return false
			this.$alert(`${store.itemName} 已加入購物車`, 'success')
			this.$store.commit('user/LOGIN_AMOUNT_CART', {
				cartItems: [store],
				callback: this.isKiosk
					? undefined
					: () => this.$router.push(`/${this.$route.params.random}/Store`),
				isResetCart: false,
			})
		},
		handleItemPicLoadError() {
			this.$set(this.product, 'itemLogoUrl', null)
		},
		handleCancelEdit() {
			this.$router.replace(`/${this.$route.params.random}/Cart`)
		},
		resetCacheCart() {
			this.$store.commit('detail/setCacheCart', { data: null, index: 0 })
		},
		handleEdit() {
			const { store, isValidate } = this.transformProduct()
			if (!isValidate) return false
			this.$store.commit('user/LOGIN_AMOUNT_CART', {
				cartItems: [store],
				callback: this.isKiosk
					? undefined
					: !this.isEdit
					? () => this.$router.push(`/${this.$route.params.random}/Store`)
					: () => this.$router.push(`/${this.$route.params.random}/Cart`),
				isResetCart: false,
				editIndex: this.$store.state.detail.cacheCartIndex,
			})
			this.resetCacheCart()
			this.$alert('更新成功!', 'success')
		},
		productSetDiscountPrice() {
			// 將product 更新自動折扣，給監聽刷新自動折扣用
			// 從store進入Detail頁面時菜單資料已經帶有折扣屬性，不需要調用此函式
			const find = this.findAutomaticDiscountByItemId(this.product.itemId)

			if (find?.automaticDiscount) {
				const { automaticDiscount, automaticDiscountItem } = find
				// vue 狀態物件中插入新屬性，使用$set才能讓畫面響應渲染
				this.$set(this.product, 'automaticDiscountId', automaticDiscount.id)
				this.$set(this.product, 'automaticDiscountName', automaticDiscount.name)
				this.$set(
					this.product,
					'automaticDiscountItemId',
					automaticDiscountItem.id,
				)
				if (automaticDiscount.discountType === 'AMOUNT') {
					this.$set(
						this.product,
						'discountPrice',
						automaticDiscount.discountAmount,
					)
				} else if (automaticDiscount.discountType === 'PERCENTAGE') {
					// 這裡會保留小數 提供數量複數時計算，注意要在商品價格呈現時取整
					// 並且不能使用折扣後金額，否則折數折扣數量複數時金額會出現取整誤差
					this.$set(
						this.product,
						'discountPrice',
						this.product.price -
							this.product.price *
								(0.01 * automaticDiscount.discountPercentage).toFixed(2),
					)
				}
			} else {
				this.$set(this.product, 'automaticDiscountId', null)
				this.$set(this.product, 'automaticDiscountName', null)
				this.$set(this.product, 'automaticDiscountItemId', null)
				this.$set(this.product, 'discountPrice', null)
			}
		},
		// 以itemId取得自動折扣
		findAutomaticDiscountByItemId(itemId) {
			let find = { automaticDiscount: null, automaticDiscountItem: null }
			find.automaticDiscount = this.itemAutomaticDiscountList.find(
				automaticDiscount => {
					return automaticDiscount.automaticDiscountItemList.find(
						automaticDiscountItem => {
							if (automaticDiscountItem.itemId === itemId) {
								find.automaticDiscountItem = automaticDiscountItem
								return true
							}
							return false
						},
					)
				},
			)

			return find
		},
	},
	watch: {
		itemAutomaticDiscountList(val, beforeVal) {
			this.productSetDiscountPrice()
		},
		isFreeItem(val, beforeVal) {
			// created不一定來的及計算 改從watch更新

			// 編輯時不更新
			if (val && !this.isEdit) {
				// 此處會將訂單自動折扣信息插入到商品裡面 (只有freeItem會這樣操作)
				this.$set(this.product, 'freeItem', true)
				this.$set(
					this.product,
					'automaticDiscountId',
					this.useOrderAutomaticDiscount.id,
				)
				this.$set(
					this.product,
					'automaticDiscountItemId',
					this.useOrderAutomaticDiscount.automaticDiscountItemList[0].id,
				)
				this.$set(
					this.product,
					'automaticDiscountName',
					this.useOrderAutomaticDiscount.name,
				)
				this.$set(this.product, 'discountPrice', this.product.price)
			}
		},
	},
}
</script>

<style lang="sass" scoped>
.page
	position: relative

.kiosk-info
	display: flex
	align-items: flex-start
	width: calc(100% - 40px)
	margin: 0 auto

	.picture
		width: 150px
		height: 150px
		margin-right: 24px
		object-fit: cover
		border-radius: 15px
		overflow: hidden

		img
			object-fit: cover

	.info-block
		flex: 1

		.title
			display: flex

			.type
				margin-right: 8px
				position: relative
				top: 2px

			.text
				font-size: 18px
				font-weight: bold

		.content
			width: 100%
			margin: 10px 0
			font-size: 16px
			color: #8E8E8E

		.panel
			display: flex
			align-items: center
			justify-content: space-between

			.amount

			.price
				color: #432D2C
				position: relative
				top: -2px

	&-automatic-discount
		color: #f37201
		display: flex
		flex-direction: column
		align-items: flex-start
		width: calc(100% - 40px)
		margin: 0 auto

		&-content
			display: flex
			justify-content: space-between
			width: 100%
			padding-left: 12px


.info
	display: flex
	flex-wrap: wrap
	border-bottom: 1px solid #D8D8D8
	padding: 20px 20px 10px

	.title
		font-size: 18px
		font-weight: bold
		flex: 1

	.price
		width: 20%
		text-align: right
		font-size: 15px
		color: #432D2C

	.content
		width: 100%
		margin-top: 10px
		font-size: 14px
		color: #8E8E8E

	.automatic-discount
		color: #f37201
		width: 100%
		margin-top: 5px
		font-size: 14px

		&-content
			margin-left: 12px
			margin-top: 7px
			display: flex
			justify-content: space-between

%comboName
	font-size: 16px
	font-weight: bold
	display: flex
	align-items: center

.option__name__expend
	font-size: 16px
	font-weight: bold
	height: 25px
	flex: 1
	padding: 0 20px
	cursor: pointer
	margin-top: 20px
	display: flex
	justify-content: space-between
	position: relative
	outline: none

	img
		transition: all 0.3s
		transform: rotate(-90deg)
		width: 18px
		height: 18px

.combo__name__expend
	font-weight: normal
	font-size: 16px
	height: 25px
	flex: 1
	cursor: pointer
	display: flex
	justify-content: space-between
	align-items: center
	position: relative
	outline: none

	img
		transition: all 0.3s
		transform: rotate(-90deg)
		width: 18px
		height: 18px

.tag
	font-size: 12.5px
	font-weight: bold
	padding: 3px 0 0 5px
	color: red
	position: absolute
	top: -3px

.quantityText
	color: gray
	margin-left: 5px
	font-size: 12.5px
	font-weight: normal

	&.active
		color: red
		margin-left: 12px

.arrow
	&.active
		transform: rotate(90deg)

.combo
	margin: 20px

	&__item
		padding-top: 10px

		&__name
			padding-bottom: 10px
			font-size: 16px
			font-weight: bold

			> .price
				margin-left: auto

			margin-bottom: 10px

		.detail_name__text
			font-weight: normal
			margin: 12px 0
			margin-left: 20px

			&.disabled
				filter: grayscale(1)
				opacity: 0.3

		> .name
			@extend %comboName
			font-weight: normal
			margin: 12px 0

			&__text
				display: flex
				flex-direction: column

				align-items: flex-start
				flex: 1

				.flavor-price
					display: flex
					align-items: center

				.content
					width: 100%
					margin-top: 10px
					font-size: 14px
					color: #8E8E8E

				&::v-deep
					label
						flex: 1

			&__black
				width: 18px
				height: 18px
				margin-left: 8px

			&__expend
				height: 18px
				width: 18px
				cursor: pointer
				margin-left: 8px

				img
					transition: all 0.3s
					transform: rotate(-90deg)
					width: 100%
					height: 100%

					&.active
						transform: rotate(90deg)

			.flavor
				padding: 12px
				background: rgba(#000, .02)
				border-radius: 8px

				&::v-deep
					.flavor
						width: 100%
						padding-top: 12px

						&:first-child
							padding-top: 0

						&:last-child
							border-bottom: 0
							padding-bottom: 0

.new-combo
	padding-bottom: 0 !important
	margin-bottom: 0 !important

.remark
	padding: 20px
	border-top: 1px solid #D8D8D8
	margin-top: -1px

	&-title
		margin-bottom: 12px
		font-weight: bold
		color: #000
		font-size: 16px

.product-img
	width: 100%
	height: 160px
	position: relative
	background: url(../../../assets/load-img2.png)
	background-size: cover
	background-position: center center

	img
		object-fit: cover
		position: absolute
		left: 0
		top: 0
		width: 100%
		height: 100%

.hide
	visibility: hidden

.control-block
	height: 135px

.errorValidate
	font-size: 12.5px
	color: red
	margin-left: 20px

	&__combo
		padding: 5px 0 0 0
		margin-left: 0 !important

.order-control
	box-shadow: 0 -5px 12px rgba(0, 0, 0, 0.16)
	position: fixed
	display: flex
	flex-direction: column
	justify-content: center
	bottom: 0
	width: 100%
	background: #fff
	padding: 20px 5px

	z-index: 1

	.btn
		border-radius: 50px

	&-bottom
		width: 100%
		display: flex
		padding: 15px 12.5px 0 12.5px
		justify-content: space-between
		align-items: center

		.automatic-discount
			color: #f37201
			width: 100%
			display: flex
			justify-content: space-between

		&:first-child
			border-bottom: 1px solid #ccc
			padding: 0 12.5px 10px 12.5px

		& .title
			font-size: 18px
			font-weight: bold
			letter-spacing: 1px

		&-text
			font-size: 18px
			font-weight: bold
			color: #666
			letter-spacing: 1px


		&-btn
			display: flex
			align-items: center

			.btn
				&:first-child
					margin-right: 10px


// 沉浸用控制框
.immerse-control-panel
	@media screen and (min-width: 600px)
		justify-content: center
		.btn
			width: 205px
</style>
